import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectCard from "../components/projectCard"
import FourColumns from "../components/layouts/fourColumns"
import AddContent from "../components/addContent"

import { css } from '@emotion/react'

const SDGTemplate = (props) => {
    const sdg = props.data.sdg.data
    const name = sdg.Name
    const desc = sdg.Description
    const projects = props.data.projects.edges.map( ({node: project}) => project)

    return (
        <Layout>
            <Seo 
                title={`${name}`}
                description={desc}
            />

            <div css={wrapper}>
                <div css={header}>
                    <Container>
                        <div className="header__main">
                            <GatsbyImage image={sdg.Icon.localFiles[0].childImageSharp.gatsbyImageData} alt={name} />
                            <div className="sdg__meta">
                                <h2>Objectif de Développement Durable (SDG)</h2>
                                <h1>SDG #{name}</h1>
                                <p>{desc}</p>
                            </div>
                        </div>
                    </Container>
                </div>

                <Container>

                    <AddContent 
                        title={`${projects.length} projet${projects.length > 1 ? 's' : ''} inspirant${projects.length > 1 ? 's' : ''} en Suisse`}
                        link={"https://airtable.com/shrHIOGBJnsvM9bgF"}
                        object="un projet"
                        style={{marginTop: "100px"}}
                    />

                    {projects && <div style={{marginTop: "100px"}}>
                        <FourColumns>
                            {projects.map( project => (
                                <ProjectCard 
                                    key={project.id}
                                    slug={project.data.Slug}
                                    name={project.data.Name}
                                    pitch={project.data.Pitch}
                                    cover={project.data.Cover && project.data.Cover.localFiles[0].childImageSharp.gatsbyImageData}
                                    logo={project.data.Logo && project.data.Logo.localFiles[0].childImageSharp.gatsbyImageData}
                                    sectors={project.data.Tags.map(tag => tag.data.Name)}
                                    devGoals={project.data.SDGs && project.data.SDGs.map( obj => ({
                                        id: obj.id,
                                        nbr: obj.data.Number,
                                    }))}
                                    mediaCount={project.data.Podcasts && project.data.Podcasts.length}
                                    peopleCount={project.data.People && project.data.People.length}
                                />
                            ))}
                        </FourColumns>
                    </div>}

                    {projects === null && <div css={noProject}>
                        Pas de projet pour cette catégorie pour le moment
                    </div>}
                </Container>
            </div>
        </Layout>
    )
}

export default SDGTemplate

const wrapper = css`
    padding: 0 0 50px;
`

const header = css`
    background-color: #fafafb;
    padding: 50px 20px;
    
    .header__main {        
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
    }

    h2 {
        font-size: 1rem;
        margin-bottom: 5px;
        opacity: .5;
    }

    h1 {
        margin-bottom: 10px;
    }

    p {
        margin: 0;
    }

    .sdg__meta {
        margin-left: 20px;

        @media screen and (max-width: 800px) {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    img {
        border-radius: 5px;
        width: 200px;
        margin: 0;
        line-height: 1;
    }

    @media screen and (min-width: 800px) {
        padding: 50px 70px;
        flex-wrap: nowrap;

        img {
            margin-bottom: 0;
        }
    }
`

const noProject = css`
    margin-top: 50px;
`

export const sdgTemplateQuery = graphql`
  query($nbr: Int!) {
    sdg: airtable(table: {eq: "SDGs"}, data: {Number: {eq: $nbr}}) {
        id
        data {
          Name
          Number
          Description
          Color
          Icon {
            id
            localFiles {
              childImageSharp {
                gatsbyImageData(
                    width: 200
                    height: 200
                    quality: 100
                )
              }
            }
          }
        }
    }
    projects: allAirtable(
        filter: {table: {eq: "Projects"}, data: {SDGs: {elemMatch: {data: {Number: {eq: $nbr}}}}, Status: {eq: "Published"}, Countries: {eq: "Suisse"}}}
      ) {
        totalCount
        edges {
          node {
            ...ProjectCardFragment
          }
        }
    }
}
`